<template>
  <div>
    <!--    logo和导航栏-->
    <div :class="show ? 'navTitle' : 'navTitle1'">
      <navigation />
    </div>
<!--    大图-->
<!--    <div class="headImg">-->
<!--      <img class="bannerIamg" :src="picPathHttpUrl" width="100%" alt="" />-->
<!--    </div>-->
    <div>
      <imageCom />
    </div>

    <!-- <div>
      <img class="headImg" :src="detailsData.picPathHttpUrl">
    </div> -->
<!--    内容-->
    <div class="home">
      <div style="margin-bottom: 60px">
        <div class="title">
         {{ detailsData.title }}
        </div>
        <div class="author">
          <span>发布时间：{{ detailsData.showTime }}</span>
          <span style="margin-left: 20px">作者：{{ detailsData.author }}</span>
          <div class="button"></div>
        </div>
        <div class="content">
          <img class="headImg" :src="detailsData.picPathHttpUrl">
          <div v-html="detailsData.content"></div>
        </div>
      </div>
      <div style="margin: 25px 0;" class="content-bottom">
        <span style="margin-bottom: 10px">网络出处：{{ detailsData.networkProvenance }}</span>
        <span >出处路径：{{ detailsData.networkPath }}</span>
      </div>
    </div>

    <!--    页脚-->
    <div>
      <footers />
    </div>
  </div>
</template>

<script>

import navigation from '@/components/home/navigation/index';
import industryInformationHeadImg from "@/components/industryInformation/industryInformationHeadImg/index"
import contentIndex from "@/components/industryInformation/content/index"
import footers from '@/components/home/footers/index';
import { queryById } from "../../api/industryInformation";
import { foregroundNavigationDetails } from '../../api/homeNav'
import imageCom from "./imageCom";
export default {
  name: "details",
  components: {
    navigation,
    industryInformationHeadImg,
    contentIndex,
    footers,
    imageCom,
  },
  data(){
    return{
      detailsData:{},
      picPathHttpUrl: '',
      show:false
    }
  },
  created() {
    window.addEventListener('scroll', () => {
      let scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
      if (scrollTop > 100) {
        this.show = true
      } else {
        this.show = false
      }
    })
     console.log('this.$route.query.id',this.$route.query.id)
    queryById(this.$route.query.id).then(res => {
      this.detailsData = res
    })
    foregroundNavigationDetails(2).then(res => {
      this.picPathHttpUrl = res.picPathHttpUrl
    })
  },
  methods:{

  }
}
</script>

<style scoped lang="less">
.title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}
.author {
  font-size: 13px;
}
.button {
  width: 100%;
  border-bottom: 1px solid #d3dce6;
  margin: 10px 0;
}
.content {
img {
  width: 100%;
}
p {
  margin: 40px 0;
}
}
.headImg {
  /*min-width: 1200px;*/
  //height: 700px;
  /*background: url("../../../static/image/case/headImg.jpg") no-repeat center center;*/
  margin: 0 auto;
  margin-bottom: 60px;
  height: 340px;
  overflow: hidden;
  //img{
  //  height: 500px;
  //  width: 100%;
  //}
}
.content-bottom{
  span{
    display: block;
    font-size: 13px;
  }
}
</style>
