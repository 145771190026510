import { render, staticRenderFns } from "./imageCom.vue?vue&type=template&id=40cc5992&scoped=true&"
import script from "./imageCom.vue?vue&type=script&lang=js&"
export * from "./imageCom.vue?vue&type=script&lang=js&"
import style0 from "./imageCom.vue?vue&type=style&index=0&id=40cc5992&scoped=true&lang=less&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40cc5992",
  null
  
)

export default component.exports