<template>
  <div style="margin-bottom: 60px">
    <div class="title">
      IXDC2020国际体验设计大会，邀你一起启
    </div>
    <div class="author">
      <span>发布时间：2020-8-18 14:30</span>
      <span style="margin-left: 20px">作者：小摹</span>
      <div class="button"></div>
    </div>
    <div class="content">
      <p>在让UI设计师产出高保真设计稿之前，产品经理需要绘制线框图，来向UI设计师传递网站布局结构、产品内容、信息化层次、界面功能、用户行为等信息。线框图没有严格的规定或秩序要遵守，但一定要清晰具体地描述布局细节，为设计师提供项目的概述。</p>
      <img src="../../../static/image/industryInformation/1.jpg" alt="">
      <p>首先我们需要搞清楚，线框图并不能算是真正意义上的原型。许多设计师和产品经理都非常容易将线框图理解为原型图。我们一起来看看线框图和原型图的区别。</p>
      <img src="../../../static/image/industryInformation/2.jpg" alt="">
      <p><b>线框图：</b>线框图是一种低保真且静态的呈现方式，只需要明确呈现内容大纲，信息结构，布局，用户界面的视觉以及交互行为描述即可。<br />
        <b>原型图：</b>原型图是程序开发过程中的重要步骤，通常是以中高保真的形式呈现，更接近最终产品的形态，并且允许我们进行一些初步的测试，用以考量产品的可用性。它能够实现和验证产品的设计理念。</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped lang="less">
.title {
  font-size: 30px;
  font-weight: 600;
  margin-bottom: 30px;
}
.author {
  font-size: 13px;
}
.button {
  width: 100%;
  border-bottom: 1px solid #d3dce6;
  margin: 10px 0;
}
.content {
  img {
    width: 100%;
  }
  p {
    margin: 40px 0;
  }
}
</style>
