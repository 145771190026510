<template>
  <div class="headImg"></div>
</template>

<script>
export default {
  name: "index"
}
</script>

<style scoped>
.headImg {
  min-width: 1200px;
  height: 340px;
  background: url("../../../static/image/industryInformation/headImg.jpg") no-repeat center center;
  margin: 0 auto;
  margin-bottom: 60px;
}
</style>
